(function ($) {

    /** Circle slider */
    (function (e) {
        e.fn.circle = function (t) {
            var n = e.extend({
                speed: "5000"
            }, t);
            return this.each(function () {
                function t() {
                    var e = i.find("li.block.active").index();
                    c.removeClass("active"), c.eq(e).addClass("active")
                }

                function o() {
                    var n;
                    i.addClass("disable-hover"), i.stop(!0, !0).animate({
                        rotatedeg: p.deg += p.step
                    }, {
                        duration: 400,
                        step: function (t) {
                            t >= 360 ? t -= 360 : t <= -360 && (t += 360), e(this).css("transform", "rotate(" + t + "deg)"), e(this).css("-webkit-transform", "rotate(" + t + "deg)")
                        },
                        complete: function () {
                            p.active = i.find("li.active").removeClass("active"), "right" == p.direction && p.step == d && (p.active.prev() && p.active.prev().length ? (n = p.active.prev().index(), p.active.prev().addClass("active")) : (p.active.siblings(":last-child").addClass("active"), n = 9)), "left" == p.direction && p.step == u && (p.active.next() && p.active.next().length ? (n = p.active.next().index(), p.active.next().addClass("active")) : (p.active.siblings(":first-child").addClass("active"), n = 0)), i.is(":animated"), i.removeClass("disable-hover"), t()
                        }
                    }, "ease")
                }

                function r() {
                    i.addClass("disable-hover"),
                        i.stop(!0, !0).animate(
                            {
                                rotatedeg: p.deg += p.step
                            },
                            {
                                duration: 400,
                                step: function (t) {
                                    t >= 360 ? t -= 360 : t <= -360 && (t += 360),
                                        e(this).css("transform", "rotate(" + t + "deg)"),
                                        e(this).css("-webkit-transform", "rotate(" + t + "deg)")
                                },
                                complete: function () {
                                    p.active = i.find("li.active"),
                                        i.is(":animated"),
                                        i.removeClass("disable-hover")
                                }
                            }, "ease")
                }

                var i = e(this),
                    s = i.find("li").length,
                    a = i.find(" > li .circle-inner"),
                    l = "count" + s,
                    u = 0,
                    c = i.next().find(".animate"),
                    p = {
                        duration: n.speed,
                        deg: 0,
                        step: u,
                        active: i.find("li.active"),
                        direction: "left"
                    };
                switch (s) {
                    case 10:
                        u = -36;
                        break;
                    case 9:
                        u = -40;
                        break;
                    case 8:
                        u = -45;
                        break;
                    case 7:
                        u = -51.5;
                        break;
                    case 6:
                        u = -60;
                        break;
                    case 5:
                        u = -72;
                        break;
                    case 4:
                        u = -90;
                        break;
                    case 3:
                        u = -120;
                        break;
                    case 2:
                        u = -180;
                        break;
                    case 1:
                        u = -360
                }
                i.addClass(l);
                var d = u - 2 * u;

                i.find("> li").first().addClass("active"),
                    i.find("> li").first().find("a").attr("href"),
                    c.eq(0).addClass("active"),
                    e(a).on("click", function () {
                        var n = e(this).parent().index() - i.find("li.active").index();
                        i.children("li").removeClass("active"),
                            e(this).parent().addClass("active"),
                            p.step = -n * d, n * d >= 288 && (p.step = -n * d + 360),
                        n * d <= -288 && (p.step = -n * d - 360),
                            r(), p.step = u, p.direction = "left", t()
                        e('.circle--slider').attr('data-index', (e('.circle--slider').find("li.block.active").index() == 6 ? 0 : e('.circle--slider').find("li.block.active").index()));
                    });
                var f = i.parent().find("div.next"),
                    h = i.parent().find("div.prev");
                f.on("click", function () {
                    i.is(":animated") || (p.direction = "left", p.step = u, o())
                    e('.circle--slider').attr('data-index', (e('.circle--slider').find("li.block.active").index()+1 == 6 ? 0 : e('.circle--slider').find("li.block.active").index()+1));
                }), h.on("click", function () {
                    i.is(":animated") || (p.direction = "right", p.step = d, o())
                    e('.circle--slider').attr('data-index', (e('.circle--slider').find("li.block.active").index()-1 == -1 ? 5 : e('.circle--slider').find("li.block.active").index()-1));
                })
            })
        }
    }(jQuery));

    $(function () {
        //Background image
        $('div').each(function () {
            var url = $(this).attr('data-image');
            if (url) {
                $(this).css('background-image', 'url(' + url + ')');
            }
        });
        $('section').each(function () {
            var url = $(this).attr('data-image');
            if (url) {
                $(this).css('background-image', 'url(' + url + ')');
            }
        });

        function autoHeightCircle() {
            var circle = $('.circle--rotate'),
                circleInner = $('.animate-wrapper'),
                circleH = circle.width(),
                circleInnerH = circleInner.width();
            circle.height(circleH);
            circleInner.height(circleInnerH);
        }

        $("#circle--rotate").circle();
        autoHeightCircle();
        $(window).resize(function () {
            autoHeightCircle();
        });
    });

    /** Counter effect */
    $(document).ready(function () {

        $.fn.isInViewport = function () {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight();

            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();

            return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        window.onload = function () {
            runAnimations();
        };

        // How long you want the animation to take, in ms
        const animationDuration = 2000;
        // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
        const frameDuration = 1000 / 60;
        // Use that to calculate how many frames we need to complete the animation
        const totalFrames = Math.round(animationDuration / frameDuration);
        // An ease-out function that slows the count as it progresses
        const easeOutQuad = t => t * (2 - t);

        // The animation function, which takes an Element
        const animateCountUp = el => {

            $(window).on('load resize scroll', function () {
                if ($(el).isInViewport() && $(el).text() == '0') {
                    let frame = 0;
                    const countTo = parseInt(el.dataset.number.replace(/,/g, ''), 10);

                    // Start the animation running 60 times per second
                    const counter = setInterval(() => {
                        frame++;
                        // Calculate our progress as a value between 0 and 1
                        // Pass that value to our easing function to get our
                        // progress on a curve
                        const progress = easeOutQuad(frame / totalFrames);
                        // Use the progress value to calculate the current count
                        const currentCount = Math.round(countTo * progress);

                        // If the current count has changed, update the element
                        if (parseInt(el.innerHTML, 10) !== currentCount) {
                            el.innerHTML = currentCount.toString().replace(/\B(?<!\.\d*)(?=(\d{0})+(?!\d))/g, ",");
                        }

                        // If we’ve reached our last frame, stop the animation
                        if (frame === totalFrames) {
                            clearInterval(counter);
                        }
                    }, frameDuration);
                }
            });
        };


        // Run the animation on all elements with a class of ‘countup’
        const runAnimations = () => {
            const countupEls = document.querySelectorAll('.countup');
            countupEls.forEach(animateCountUp);
        };
    });

    /** Service slider */
    $('.service-slider').slick({
        arrows: false,
        dots: false,
        swipeToSlide: true,
        infinite: true,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 1000,
        variableWidth: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    /** Client logo slider */
    $('.client-logo-slider').slick({
        arrows: true,
        dots: false,
        swipeToSlide: true,
        infinite: true,
        speed: 300,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: $('.btn-client-logo-slider-arrow-prev'),
        nextArrow: $('.btn-client-logo-slider-arrow-next'),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    });

})
(jQuery);